import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ApiClient from "../../../services/apiclient";
import { nextStep } from "../../../store/actions/PrestamosPersonalesActions";
import { Formik, Form, Field, ErrorMessage, useFormik } from "formik";
import * as Yup from "yup";
import AlertMessage from "../../alert.component";
import TestComponent from "../../test.component";
import LoanType from "../../loan-type.component";
import { useHistory } from "react-router-dom";
import Footer from "../../front/FooterI";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import logo_balnco from "../../../img/logo_blanco.png";
import { motion } from "framer-motion";
import Navbar from "../../front/NavbarI";
import Button from "@mui/material/Button";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { TransitionProps } from "@mui/material/transitions";
import { cdcInfo } from "../../forms";
import Grid from "@mui/material/Grid";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { PreTramiteCreditoContext } from "../../../context/PreTramiteCreditoProvider";
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TermsConditionsCDC = ({ nextStep, nominaId }) => {
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickOpenASD = () => {
    setOpenASD(true);
  };

  const handleClose2 = () => {
    if (!changed) {
      formik2.setValues(init);
    }
    setOpen(false);
  };
  const handleClose2ASD = () => {
    setOpenASD(false);
  };
  const history = useHistory();
  const apiClient = ApiClient.getInstance();
  const [avisoDePrivacidad, setavisoDePrivacidad] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [openASD, setOpenASD] = React.useState(false);
  const handleChange = () => {
    setavisoDePrivacidad(!avisoDePrivacidad);
  };
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [isLoading, setIsLoading] = useState(false);
  const [amount, setAmount] = useState(0);
  const [maxWidth, setMaxWidth] = React.useState<DialogProps["maxWidth"]>("xl");
  const [init, setInit] = useState<cdcInfo>();
  const [changed, setChanged] = useState(false);
  const [colonias, setColonias] = useState([]);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isRenovacion, setIsRenovacion] = useState(false);
  const { preCredito, updatePreCredito, deletePreCredito } = React.useContext(
    PreTramiteCreditoContext
  );
  const validationSchema = Yup.object().shape({
    val1: Yup.string().required("El código NIP es requerido"),
    val2: Yup.string().required("El código NIP es requerido"),
    val3: Yup.string().required("El código NIP es requerido"),
    val4: Yup.string().required("El código NIP es requerido"),
    val5: Yup.string().required("El código NIP es requerido"),
    val6: Yup.string().required("El código NIP es requerido"),
    attached1: Yup.boolean()
      .required("The terms and conditions must be accepted.")
      .oneOf([true], "Field must be checked"),
  });

  localStorage.getItem("loanType");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenMessage(false);
  };

  function handleChangeCode(e) {
    console.log(e.target);
    const { maxLength, value, name } = e.target;
    const [fieldName, fieldIndex] = name.split("-");
    console.log(fieldIndex);
    if (value.length >= maxLength) {
      if (parseInt(fieldIndex, 10) < 6) {
        const nextSibling = document.querySelector(
          `input[name=val-${parseInt(fieldIndex, 10) + 1}]`
        ) as HTMLElement | null;

        if (nextSibling !== null) {
          nextSibling.focus();
        }
      } else if (parseInt(fieldIndex, 10) === 6) {
        formik.handleSubmit();
      }
    }
    formik.setFieldValue([`val${fieldIndex}`].toString(), value);
  }
  const sendSms = async() =>{
    await apiClient.sendSmsCdc().then((data: any) =>{

    })
  }
  const sendWhatsApp = async() =>{
    await apiClient.sendSmsWhatsAppCdc().then((data: any) =>{

    })
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      val1: "",
      val2: "",
      val3: "",
      val4: "",
      val5: "",
      val6: "",
      attached1: false,
    },
    validationSchema: Yup.object({
      attached1: Yup.boolean()
        .required("The terms and conditions must be accepted.")
        .oneOf([true], "Field must be checked"),
    }),
    onSubmit: (values) => {
      setIsLoading(true);
      let code: string = [
        formik.values.val1,
        formik.values.val2,
        formik.values.val3,
        formik.values.val4,
        formik.values.val5,
        formik.values.val6,
      ].join("");
      console.log(code);
      apiClient.validateCirculoCredito(code, isRenovacion).then((response) => {
        console.log(response);
        if(response.error) {
          
          if (response.error.message === "Tu solicitud no puede ser procesada") {
            if (localStorage.getItem("preCreditoLocal")) {
              var credito = JSON.parse(localStorage.getItem("preCreditoLocal"));
              if (
                credito.idProcessMarketing !== "" &&
                credito.typeLoanMarketing === "Personal"
              ) {
                var dd = {
                  processName: "cdc-form-denied",
                };
                updatePreCredito({
                  ...preCredito,
                  stepMarketing: "address-form-finish",
                });
                updateMarketing(credito.idProcessMarketing, dd);
              }
            }
            history.push("/RECHAZADO/personal/cdc-denied");
          } else if(response.error.message === "Código NIP inválido") {
            setIsLoading(false)
            setOpenMessage(true)
            setMessage(response.error.message)
            setSeverity("error")
          } else {
            if (localStorage.getItem("preCreditoLocal")) {
              var credito = JSON.parse(localStorage.getItem("preCreditoLocal"));
              if (
                credito.idProcessMarketing !== "" &&
                credito.typeLoanMarketing === "Personal"
              ) {
                var dd = {
                  processName: "cdc-form-denied",
                };
                updatePreCredito({
                  ...preCredito,
                  stepMarketing: "address-form-finish",
                });
                updateMarketing(credito.idProcessMarketing, dd);
              }
            }
            history.push("/RECHAZADO/personal/cdc-denied");
          }
        } else {
          if (localStorage.getItem("preCreditoLocal")) {
            var credito = JSON.parse(localStorage.getItem("preCreditoLocal"));
            if (
              credito.idProcessMarketing !== "" &&
              credito.typeLoanMarketing === "Personal"
            ) {
              var dd = {
                processName: "cdc-form-finish",
              };
              updatePreCredito({
                ...preCredito,
                stepMarketing: "cdc-form-finish",
              });
              updateMarketing(credito.idProcessMarketing, dd);
            }
          }
          nextStep("PERSONAL-REFERENCES");
        }
      });
    },
  });
  const updateMarketing = async (id: any, data: any) => {
    await apiClient.updateMarketingTrack(id, data);
  };
  const checkActiveCredit = async () => {
    const activeCredit = await apiClient.getActiveCredit();

    const credit = await apiClient.getCredit(activeCredit.creditType);
    if(credit){
      
    console.log(credit.user?.isRenovacion === true ? true : false);
      setIsRenovacion(credit.user?.isRenovacion === true ? true : false);
    } else {
      setIsRenovacion(false);
    }
  };
  useEffect(() => {
    const preInfo = async () => {
      await apiClient
        .getCDCDataUser()
        //.then(res => res.json())
        .then(async (data) => {
          console.log("calle", data);
          await setData(data);
          apiClient
            .getAdrressFromZipCode(data.address.postalCode)
            .then((data) => {
              setColonias(data?.colonias);
            })
            .catch((e) => console.log(e));
        });
    };
    
    if (localStorage.getItem("userLocal")) {
      var userLocal = JSON.parse(localStorage.getItem("userLocal"));
      setAmount(parseInt(userLocal.preAmount));
    }
    preInfo();
    if (localStorage.getItem("preCreditoLocal")) {
      var credito = JSON.parse(localStorage.getItem("preCreditoLocal"));
      if (
        credito.stepMarketing != "cdc-form-init" &&
        credito.typeLoanMarketing === "Personal"
      )
        var data = {
          processName: "cdc-form-init",
        };
      updatePreCredito({
        ...preCredito,
        stepMarketing: "cdc-form-init",
      });
      updateMarketing(credito.idProcessMarketing, data);
    }
    
    checkActiveCredit();
  }, []);



  const setData = async (data: any) => {
    setInit({
      rfc: data.rfc,
      address: {
        street: data.address.street,
        city: data.address.city,
        delegation: data.address.delegation,
        exteriorNumber: data.address.exteriorNumber,
        postalCode: data.address.postalCode,
        state: data.address.state,
        suburb: data.address.suburb,
      },
      userDetails: {
        firstName: data.userDetails.firstName,
        secondName: data.userDetails.secondName,
        birthDate: data.userDetails.birthDate,
        lastFName: data.userDetails.lastFName,
        lastMName: data.userDetails.lastMName,
      },
    });
    formik2.setValues({
      rfc: data.rfc,
      address: {
        street: data.address.street,
        city: data.address.city,
        delegation: data.address.delegation,
        exteriorNumber: data.address.exteriorNumber,
        postalCode: data.address.postalCode,
        state: data.address.state,
        suburb: data.address.suburb,
      },
      userDetails: {
        firstName: data.userDetails.firstName,
        secondName: data.userDetails.secondName,
        birthDate: data.userDetails.birthDate,
        lastFName: data.userDetails.lastFName,
        lastMName: data.userDetails.lastMName,
      },
    });
  };
  const formik2 = useFormik({
    initialValues: {
      rfc: "",
      address: {
        street: "",
        exteriorNumber: "",
        suburb: "",
        delegation: "",
        city: "",
        state: "",
        postalCode: "",
      },
      userDetails: {
        lastFName: "",
        lastMName: "",
        firstName: "",
        secondName: "",
        birthDate: "",
      },
    },
    validationSchema: Yup.object().shape({
      address: Yup.object().shape({
        postalCode: Yup.string()
          .matches(/(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/)
          .required(),
        street: Yup.string().required(),
        exteriorNumber: Yup.string().required(),
        state: Yup.string().required(),
        city: Yup.string().required(),
        delegation: Yup.string().required(),
        suburb: Yup.string().required(),
      }),
      userDetails: Yup.object().shape({
        firstName: Yup.string().required(),
        lastFName: Yup.string().required(),
        lastMName: Yup.string().required(),
        birthDate: Yup.string().required(),
      }),
      rfc: Yup.string()
        .required("Requerido")
        .matches(/^[a-zA-Z]{3,4}(\d{6})((\D|\d){3})?$/, "Ingrese RFC valido"),
    }),
    onSubmit: (values) => {
      setIsLoading2(true);

      console.log(values);
      apiClient.updateInfoCdc(values).then((response) => {
        console.log(response);
        if (response.error) {
          setIsLoading2(false);
          formik2.setValues(init);
          setOpen(false);
          setOpenMessage(true);
          setMessage(response.error.message);
          setSeverity("error");
        }
        setData(response);
        setIsLoading2(false);
        setOpen(false);
        setOpenMessage(true);
        setMessage("Datos actualizados correctamente");
        setSeverity("success");
      });
    },
  });
  useEffect(() => {
    setChanged(
      JSON.stringify(formik2.values) === JSON.stringify(init) ? true : false
    );
  }, [formik2.values]);
  useEffect(() => {
    const setPostalCode = () => {
      if (formik2.values.address.postalCode.trim().length == 5) {
        const postalCode = formik2.values.address.postalCode.trim();
        apiClient
          .getAdrressFromZipCode(postalCode)
          .then((data) => {
            formik.setFieldValue(
              "postalCode",
              formik2.values.address.postalCode
            );
            formik2.setFieldValue("address.delegation", data.municipio);
            formik2.setFieldValue(
              "address.city",
              data.ciudad || data.municipio
            );
            formik2.setFieldValue("address.state", data.estado);
            setColonias(data?.colonias);
          })
          .catch((e) => console.log(e));
      }
    };

    if (formik2.values.address.postalCode !== "") {
      setPostalCode();
    }
  }, [formik2.values.address.postalCode]);
  const submit = () => {
    formik2.handleSubmit();
  };
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ ease: "easeInOut", duration: 0.6 }}
    >
      <Navbar />
      <br></br>
      <AlertMessage
        open={openMessage}
        message={message}
        handleClose={handleClose}
        severity={severity}
      />
      <Dialog
        open={open}
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.appBar + 1 }}
          open={isLoading2}
        >
          <img
            src={logo_balnco}
            alt="PRADO'S"
            className="img-fluid p-3 img-client"
            width="300"
          />
          <CircularProgress color="primary" size={80} />
        </Backdrop>
        <DialogTitle
          sx={{
            backgroundColor: "#005593",
            color: "white",
            fontWeight: 700,
            textAlign: "center",
          }}
          id = "testModalData"
        >
          {" "}
          <Grid container spacing={3}>
            <Grid item xs={4} md={2}></Grid>
            <Grid item xs={4} md={8}>
              {"¿Tus datos son correctos?"}
            </Grid>
            <Grid item xs={4} md={2}>
              <IconButton
                aria-label="delete"
                color="inherit"
                onClick={handleClose2}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent>
          <div className="d-flex justify-content-center mt-1 container">
            <div className="card p-1 card-redounded-lg shadow ">
              <div className="container">
                <form id="div-perfil" onSubmit={formik2.handleSubmit}>
                  <p className="txt-blue-strong">Información personal</p>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                      <div className="mb-1">
                        <label
                          htmlFor="firstName"
                          className="form-label sol1-tittle"
                        >
                          Nombre (s)*{" "}
                        </label>
                        <input
                          type="input"
                          className={
                            Boolean(formik2.errors.userDetails?.firstName)
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          id="userDetails.firstName"
                          name="userDetails.firstName"
                          aria-describedby="emailHelp"
                          value={formik2.values.userDetails?.firstName}
                          onChange={formik2.handleChange}
                        />
                      </div>

                      <div className="mb-1">
                        <label
                          htmlFor="lastFName"
                          className="form-label sol1-tittle"
                        >
                          Apellido paterno *
                        </label>
                        <input
                          type="input"
                          className={
                            Boolean(formik2.errors.userDetails?.lastFName)
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          id="userDetails.lastFName"
                          aria-describedby="emailHelp"
                          value={formik2.values.userDetails?.lastFName}
                          onChange={formik2.handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                      <div className="mb-1">
                        <label
                          htmlFor="secondName"
                          className="form-label sol1-tittle"
                        >
                          Segundo nombre *
                        </label>
                        <input
                          type="input"
                          className={
                            Boolean(formik2.errors.userDetails?.secondName)
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          id="userDetails.secondName"
                          value={formik2.values.userDetails?.secondName}
                          onChange={formik2.handleChange}
                        />
                      </div>
                      <div className="mb-1">
                        <label
                          htmlFor="lastMName"
                          className="form-label sol1-tittle"
                        >
                          Apellido materno *
                        </label>
                        <input
                          type="input"
                          className={
                            Boolean(formik2.errors.userDetails?.lastMName)
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          id="userDetails.lastMName"
                          value={formik2.values.userDetails?.lastMName}
                          onChange={formik2.handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                      <div className="mb-3">
                        <label
                          htmlFor="secondName"
                          className="form-label sol1-tittle"
                        >
                          Fecha de nacimiento *
                        </label>
                        <input
                          type="date"
                          className={
                            Boolean(formik2.errors.userDetails?.birthDate)
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          id="userDetails.birthDate"
                          value={formik2.values.userDetails?.birthDate.substring(
                            0,
                            10
                          )}
                          onChange={formik2.handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                      <div className="mb-1">
                        <label
                          htmlFor="lastMName"
                          className="form-label sol1-tittle"
                        >
                          RFC *
                        </label>
                        <input
                          type="input"
                          className={
                            Boolean(formik2.errors.rfc)
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          id="rfc"
                          value={formik2.values.rfc}
                          onChange={formik2.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <p className="txt-blue-strong">Dirección</p>
                  <hr className="mt-4 mb-2" />
                  <div className="mb-1">
                    <label htmlFor="street" className="form-label sol1-tittle">
                      Calle *
                    </label>
                    <input
                      type="input"
                      id="address.street"
                      name="address.street"
                      aria-describedby="emailHelp"
                      onChange={formik2.handleChange}
                      value={formik2.values.address.street}
                      onBlur={formik2.handleBlur}
                      className={
                        Boolean(formik2.errors.address?.street)
                          ? " form-control is-invalid"
                          : "form-control"
                      }
                    />
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                      <div className="mb-1">
                        <label
                          htmlFor="exteriorNumber"
                          className="form-label sol1-tittle"
                        >
                          Numero exterior *
                        </label>
                        <input
                          type="input"
                          id="address.exteriorNumber"
                          aria-describedby="emailHelp"
                          onChange={formik2.handleChange}
                          value={formik2.values.address.exteriorNumber}
                          onBlur={formik2.handleBlur}
                          className={
                            Boolean(formik2.errors.address?.exteriorNumber)
                              ? " form-control is-invalid"
                              : "form-control"
                          }
                        />
                      </div>
                      <div className="mb-1">
                        <label
                          htmlFor="suburb"
                          className="form-label sol1-tittle"
                        >
                          Colonia
                        </label>
                        <select
                          id="address.suburb"
                          aria-describedby="emailHelp"
                          onChange={formik2.handleChange}
                          value={formik2.values.address.suburb}
                          onBlur={formik2.handleBlur}
                          className={
                            formik2.errors.address?.suburb
                              ? " form-select slect-blue text-light is-invalid"
                              : "form-select slect-blue text-light"
                          }
                        >
                          <option value="">Seleccione una opción</option>
                          {colonias.map((data) => {
                            return (
                              <option key={data?.colonia} value={data?.colonia}>
                                {data?.colonia}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                      <div className="mb-1">
                        <label
                          htmlFor="postalCode"
                          className="form-label sol1-tittle"
                        >
                          Código postal *
                        </label>
                        <input
                          type="input"
                          id="address.postalCode"
                          aria-describedby="emailHelp"
                          onChange={formik2.handleChange}
                          value={formik2.values.address.postalCode}
                          onBlur={formik2.handleBlur}
                          className={
                            Boolean(formik2.errors.address?.postalCode)
                              ? " form-control is-invalid"
                              : "form-control"
                          }
                        />
                      </div>
                      <div className="mb-1">
                        <label
                          htmlFor="city"
                          className="form-label sol1-tittle"
                        >
                          Ciudad *
                        </label>
                        <input
                          type="input"
                          id="address.city"
                          aria-describedby="emailHelp"
                          onChange={formik2.handleChange}
                          value={formik2.values.address.city}
                          onBlur={formik2.handleBlur}
                          disabled
                          className={
                            Boolean(formik2.errors.address?.city)
                              ? " form-control is-invalid"
                              : "form-control"
                          }
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                      <div className="mb-1">
                        <label
                          htmlFor="delegation"
                          className="form-label sol1-tittle"
                        >
                          Delegación *
                        </label>
                        <input
                          type="input"
                          id="address.delegation"
                          aria-describedby="emailHelp"
                          onChange={formik2.handleChange}
                          value={formik2.values.address.delegation}
                          onBlur={formik2.handleBlur}
                          disabled
                          className={
                            Boolean(formik2.errors.address?.delegation)
                              ? " form-control is-invalid"
                              : "form-control"
                          }
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                      <div className="mb-1">
                        <label
                          htmlFor="state"
                          className="form-label sol1-tittle"
                        >
                          Estado *
                        </label>
                        <input
                          type="input"
                          id="address.state"
                          aria-describedby="emailHelp"
                          onChange={formik2.handleChange}
                          value={formik2.values.address.state}
                          onBlur={formik2.handleBlur}
                          disabled
                          className={
                            Boolean(formik2.errors.address?.state)
                              ? " form-control is-invalid"
                              : "form-control"
                          }
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" disabled={changed} onClick={submit}>
            Guardar
          </Button>
          <Button variant="contained" color="error" onClick={handleClose2}>
            Descartar
          </Button>
        </DialogActions>
      </Dialog>

      <div className="grey-background">
        <br />
        <LoanType
          loanType={localStorage.getItem("loanType")}
          userValidated={true}
        />
        <div className="d-block d-sm-block d-md-none p-0 m-0 mb-5">
          <motion.div
            initial={{ y: "50%", opacity: 0, scale: 0.5 }}
            animate={{ y: 0, opacity: 1, scale: 1 }}
            exit={{ y: "50%", transition: { duration: 0.8 } }}
            transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
            className="card card-monto"
          >
            <div className="container">
              <h6 className="text-credit text-end p-2">Monto solicitado:</h6>
              <h2 className="text-light-blue text-end">
                {" "}
                {amount.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </h2>
            </div>
          </motion.div>
        </div>
        <motion.div
          initial={{ y: "50%", opacity: 0, scale: 0.5 }}
          animate={{ y: 0, opacity: 1, scale: 1 }}
          exit={{ y: "50%", transition: { duration: 0.8 } }}
          transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
          className="row"
        >
          <div className="col-3">
            <div className="d-none d-sm-none d-md-block p-0 m-0 ">
              <div className="card card-monto">
                <div className="container">
                  <h6 className="text-credit text-end p-2">
                    Monto solicitado:
                  </h6>
                  <h2 className="text-light-blue text-end">
                    {" "}
                    {amount.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="text-center">
              <h3 className="sol1-tittle">Solicitud de préstamo {isRenovacion}</h3>
              <p>Por favor, rellena los siguientes campos con tu información</p>
            </div>
          </div>
          <div className="col-3"></div>
        </motion.div>

        {/* STEPS */}
        <motion.div
          initial={{ y: "50%", opacity: 0, scale: 0.5 }}
          animate={{ y: 0, opacity: 1, scale: 1 }}
          exit={{ y: "50%", transition: { duration: 0.8 } }}
          transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
          className="d-none d-sm-none d-md-block p-0 m-0 "
        >
          <div className="d-flex justify-content-center mt-4 container">
            <button className="circle-step-blue">
              <h5 className="numbre-step">1</h5>
            </button>
            <div className="line">
              aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa
            </div>
            <button className="circle-step-blue">
              <h5 className="numbre-step">2</h5>
            </button>
            <div className="line">
              aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa
            </div>
            <button className="circle-step-blue">
              <h5 className="numbre-step">3</h5>
            </button>
          </div>
        </motion.div>
        <motion.div
          initial={{ y: "50%", opacity: 0, scale: 0.5 }}
          animate={{ y: 0, opacity: 1, scale: 1 }}
          exit={{ y: "50%", transition: { duration: 0.8 } }}
          transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
          className="d-block d-sm-block d-md-none p-0 m-0 "
        >
          <div className="d-flex justify-content-center mt-4 container">
            <button className="circle-step-blue">
              <h5 className="numbre-step">1</h5>
            </button>
            <div className="line">aaaaaaaaaaaa</div>
            <button className="circle-step-blue">
              <h5 className="numbre-step">2</h5>
            </button>
            <div className="line">aaaaaaaaaaaa</div>
            <button className="circle-step-blue ">
              <h5 className="numbre-step">3</h5>
            </button>
          </div>
        </motion.div>

        {/* CARDS */}
        <motion.div
          initial={{ y: "50%", opacity: 0, scale: 0.5 }}
          animate={{ y: 0, opacity: 1, scale: 1 }}
          exit={{ y: "50%", transition: { duration: 0.8 } }}
          transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
          className="d-flex justify-content-center mt-4 container"
        >
          <div className="card p-4 card-redounded-lg shadow p-3 mb-5 bg-body">
            <div className="container">
              <div className="text-center">
                {isRenovacion ? (<h3 className="text-strong-blue">
                  Términos y condiciones <br />
                  de autorización de consulta con renovacion
                </h3>):(<h3 className="text-strong-blue">
                  Términos y condiciones <br />
                  de autorización de consulta sin renovacion
                </h3>)
                }
                
              </div>
              <div className="d-flex justify-content-center mt-5">
                <div className="form-check">
                  <input
                    className={
                      formik.errors.attached1
                        ? "form-check-input checkbox-terms  is-invalid"
                        : "form-check-input checkbox-terms"
                    }
                    type="checkbox"
                    //value=""
                    id="attached1"
                    //checked={formik.values.attached1}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <label
                    className="form-check-label text-check"
                    htmlFor="flexCheckDefault"
                  >
                    Acepto{" "}
                    <a
                      href="/Terminos-y-condiciones"
                      target="_blank"
                      className="text-light-blue"
                    >
                      Términos y Condiciones
                    </a>{" "}
                    y cláusula de medios electrónicos tales como NIP.
                  </label>
                </div>
              </div>
              <div className="container mt-5">
                <h5 className="text-strong-blue">
                  Autorización para solicitar reportes de crédito
                </h5>
                <p>
                  Autorizo expresamente a PROBENSO GROUP S.A. S.O.F.O.M. E.N.R,
                  para que lleve a cabo investigación sobre mi comportamiento
                  crediticio en las sociedades de información crediticia (SIC)
                  que estime conveniente. Conozco la naturaleza y alcance de la
                  información que se solicitará, del uso que se le dará y que se
                  podrán realizar consultas periódicas de mi historial
                  crediticio.
                  <br />
                  <br />
                  Consiento que esta autorización tenga una vigencia de 3 años
                  contando a partir de hoy, y en su caso mientras mantengamos
                  relación jurídica. Acepto que este documento quede bajo
                  propiedad de PROBENSO GROUP S.A. S.O.F.O.M. E.N.R, para
                  efectos de control y cumplimiento del articulo 28 de la LRSIC.
                </p>
              </div>
              <Button variant="outlined" onClick={handleClickOpen}>
                Validar información
              </Button>
              <form onSubmit={formik.handleSubmit}>
                <hr className="mt-4 mb-4" />
                <div className="text-center mt-5">
                  <p>Te enviamos un código NIP vía SMS</p>
                  <p className="sol1-tittle">Código NIP*</p>
                </div>
                <div className="d-flex justify-content-center">
                  <div className="p-2">
                    <input
                      type="string"
                      className="form-control input-number "
                      id="val-1"
                      aria-describedby="emailHelp"
                      name="val-1"
                      onChange={handleChangeCode}
                      maxLength={1}
                      value={
                        Object.keys(formik.errors).length > 0
                          ? ""
                          : formik.values.val1
                      }
                      disabled={
                        formik.values.attached1 === false ? true : false
                      }
                    />
                  </div>
                  <div className="p-2">
                    <input
                      type="string"
                      className="form-control input-number"
                      id="val-2"
                      aria-describedby="emailHelp"
                      name="val-2"
                      onChange={handleChangeCode}
                      maxLength={1}
                      value={
                        Object.keys(formik.errors).length > 0
                          ? ""
                          : formik.values.val2
                      }
                      disabled={
                        formik.values.attached1 === false ? true : false
                      }
                    />
                  </div>
                  <div className="p-2">
                    <input
                      type="string"
                      className="form-control input-number"
                      id="val-3"
                      aria-describedby="emailHelp"
                      name="val-3"
                      onChange={handleChangeCode}
                      maxLength={1}
                      value={
                        Object.keys(formik.errors).length > 0
                          ? ""
                          : formik.values.val3
                      }
                      disabled={
                        formik.values.attached1 === false ? true : false
                      }
                    />
                  </div>
                  <div className="p-2">
                    <input
                      type="string"
                      className="form-control input-number"
                      id="val-4"
                      aria-describedby="emailHelp"
                      name="val-4"
                      onChange={handleChangeCode}
                      maxLength={1}
                      value={
                        Object.keys(formik.errors).length > 0
                          ? ""
                          : formik.values.val4
                      }
                      disabled={
                        formik.values.attached1 === false ? true : false
                      }
                    />
                  </div>
                  <div className="p-2">
                    <input
                      type="string"
                      className="form-control input-number"
                      id="val-5"
                      aria-describedby="emailHelp"
                      name="val-5"
                      onChange={handleChangeCode}
                      maxLength={1}
                      value={
                        Object.keys(formik.errors).length > 0
                          ? ""
                          : formik.values.val5
                      }
                      disabled={
                        formik.values.attached1 === false ? true : false
                      }
                    />
                  </div>
                  <div className="p-2">
                    <input
                      type="string"
                      className="form-control input-number"
                      id="val-6"
                      aria-describedby="emailHelp"
                      name="val-6"
                      onChange={handleChangeCode}
                      maxLength={1}
                      value={
                        Object.keys(formik.errors).length > 0
                          ? ""
                          : formik.values.val6
                      }
                      disabled={
                        formik.values.attached1 === false ? true : false
                      }
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-4"></div>
                  <div className="col-4 text-center">
                    <a onClick={() => sendSms()} className="btn btn-secondary">
                      Reenviar el código por SMS
                    </a>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-4"></div>
                  <div className="col-4 text-center">
                    <a
                      onClick={sendWhatsApp}
                      className="btn btn-success"
                    >Reenviar código por WhatsApp <i className="fa-brands fa-whatsapp fa-xl"></i>
                    </a>
                  </div>
                  <div className="col-4"></div>
                </div>
                {/* <div className="d-flex justify-content-center mt-5">
                                <a href='/solicitud/personal/8' className="btn btn-shadow-3">
                                    Autorizo
                                </a>
                            </div> */}
              </form>
              {/* <div className="d-flex justify-content-start mt-5 mb-4">
                            <a href='/solicitud/personal/6' className="btn btn-shadow-3">
                            <img src={flechai} alt="PRADO'S" className="img-fluid mrirht" width="10" />
                                Regresar
                            </a>
                        </div> */}
            </div>
          </div>
        </motion.div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.appBar + 1 }}
          open={isLoading}
        >
          <img
            src={logo_balnco}
            alt="PRADO'S"
            className="img-fluid p-3 img-client"
            width="300"
          />
          <CircularProgress color="primary" size={80} />
        </Backdrop>
        <Footer />
      </div>
    </motion.div>
  );
};

const mapStateToProps = ({ personalCreditReducer }) => ({
  user: personalCreditReducer?.user ?? {},
  nominaId: personalCreditReducer?.nominaId ?? "",
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      nextStep,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TermsConditionsCDC);
