import React, { useEffect, useState } from "react";
import { createVeriffFrame, MESSAGES } from "@veriff/incontext-sdk";
import { useHistory, useLocation } from "react-router-dom";
import HeaderDashboard from "../../../front/NavbarI";
import ApiClient from "../../../../services/apiclient";
import fondo_1 from "../../../../img/fondo_1.png";
import fondo_2 from "../../../../img/fondo_2.png";
import logo_balnco from "../../../../img/logo_blanco.png";
import LoanType from "../../../loan-type.component";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const Veriff = () => {
  const apiClient = ApiClient.getInstance();
  const currentUserId = apiClient.getUserId();
  const query = useQuery();
  const history = useHistory();

  const [finished, setFinished] = useState(false);
  
  localStorage.getItem("loanType");

  const createNewVeriffSession = async () => {
    
    const {creditType, id} = await apiClient.getActiveCredit();
    try {
      const session = await apiClient.createVeriffSession("ID_CARD", creditType, id);
      console.log("new session", session);

      return session;
    } catch (error: any) {
      console.log(error)
      if(error.name === "VeriffLimitSessions") {
        console.log("error.message === 'Has superado el límite de sesiones.");
        if(creditType === "pabs"){
          return history.push("/RECHAZADO/pabs/DENIED");
      } else {
        return history.push("/RECHAZADO/personal/DENIED");
      }
      } else{ 
      return null;
      }
    }
  };

  const checkUserVeriffValidated = async () => {
    const from = query.get("from");

    const isValidatedRes = await apiClient.checkUserVeriffValidated();
    if (isValidatedRes.veriffValidated === true) {
      history.push(from ? "/" + from : "/");
      return;
    }

    // try {
    //   const activeSession = await apiClient.getActiveVeriffSession();
    //   openVeriffFrame(activeSession.sessionURL);
    // } catch (error) {
    //   if (error.name === "VeriffSessionNotFound") {
    //     const session = await apiClient.createVeriffSession("ID_CARD");
    //   }
    // }

    await apiClient
      .getActiveVeriffSession()
      .then((activeSession) => {
        console.log("activeSession", activeSession);
        openVeriffFrame(activeSession.sessionURL);
      })
      .catch(async (error) => {
        console.log(error)
        if (error.name === "VeriffSessionNotFound") {
          const session = await createNewVeriffSession();
          if (session) openVeriffFrame(session.sessionURL);
        }
      });
  };

  const openVeriffFrame = async (sessionURL: string) => {
    try {
      const veriffFrame = createVeriffFrame({
        url: sessionURL,
        lang: "es-mx",
        onEvent: (msg) => {
          if (msg === MESSAGES.CANCELED) {
            console.log("Veriff canceled");
          } else if (msg === MESSAGES.FINISHED) {
            setFinished(true);
          }
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const from = query.get("from");
      const getPersonalLoad = async () => {
        if(from == "prestamo-personal"){
          let response = await apiClient.personalLoan(currentUserId);
          const data = await response.json();
          console.log("data prestamo personla", data);
    
          if (data?.step !== "CDC") {
            history.push("/prestamo-personal");
          } 
        }

      };
    checkUserVeriffValidated();
  }, []);

  return (
    <div className="blue-background-mess">
      <HeaderDashboard />
      <br />
      <LoanType
        loanType={localStorage.getItem("loanType")}
        userValidated={true}
      />
      <div className="d-none d-sm-none d-md-block p-0 m-0 ">
        <div className="row">
          <div className="col-2">
            <img
              src={fondo_2}
              alt="PRADO'S"
              className="img-fluid img-top-sol8"
              width="250"
            />
          </div>
          <div className="col-8">
            <div className="d-flex justify-content-center mb-1">
              <img
                src={logo_balnco}
                alt="PRADO'S"
                className="img-fluid p-2 img-client mt-5 mb-4"
                width="240"
              />
            </div>
            <div className="text-white text-center">
              <h2 className="mt-2 text-tittle">
                ¡Necesitamos validar tu identidad!
              </h2>
              {finished ? (
                <>
                  <p>
                    Muchas gracias! Sus datos se estan verificando. Por favor
                    espere unos minutos y compruebe sus resultados.
                  </p>
                  <button
                    onClick={() => checkUserVeriffValidated()}
                    className="btn btn-continue mt-5 mb-5"
                  >
                    Comprobar
                  </button>
                </>
              ) : (
                <>
                  <p>
                    A continuación se te solicitara que verifiques tu identidad.
                  </p>
                  <button
                    onClick={() => checkUserVeriffValidated()}
                    className="btn btn-continue mt-5 mb-5"
                  >
                    Volver a intentarlo
                  </button>
                </>
              )}
            </div>
          </div>
          <div className="col-2">
            <img
              src={fondo_1}
              alt="PRADO'S"
              className="img-fluid img-top2-sol8"
              width="260"
            />
          </div>
        </div>
      </div>
      <div className="d-block d-sm-block d-md-none p-0 m-0 ">
        <div className="d-flex justify-content-center mb-1">
          <img
            src={logo_balnco}
            alt="PRADO'S"
            className="img-fluid p-2 img-client mt-5 mb-4"
            width="200"
          />
        </div>
        <div className="text-white text-center container">
          <h2 className="mt-1 text-tittle">
            ¡Necesitamos validar tu identidad!
          </h2>
          {finished ? (
            <>
              <p>
                Muchas gracias! Sus datos se estan verificando. Por favor espere
                unos minutos y compruebe sus resultados.
              </p>
              <button
                onClick={() => checkUserVeriffValidated()}
                className="btn btn-continue mt-5 mb-5"
              >
                Comprobar
              </button>
            </>
          ) : (
            <>
              <p>
                A continuación se te solicitara que verifiques tu identidad.
              </p>
              <button
                onClick={() => checkUserVeriffValidated()}
                className="btn btn-continue mt-5 mb-5"
              >
                Volver a intentarlo
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
