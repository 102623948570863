import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import logo_balnco from "../../../img/logo_blanco.png";
import Navbar from "../../front/NavbarI";
import Footer from "../../front/FooterI";
import { DomicilioContext } from "./../../../context/DomicilioProvider";
import { TramiteCreditoContext } from "./../../../context/TramiteCreditoProvider";
import { UserContext } from "./../../../context/UserProvider";
import AlertMessage from "../../alert.component";
import ApiClient from "../../../services/apiclient";
import { PABS_ACTIVE, PERSONAL_ACTIVE } from "../../../utils/config";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { motion } from "framer-motion";
import { useAnimation, useInView } from "framer-motion";
function Login() { 
  const ref = useRef(null);
  const inView = useInView(ref);
  const animation = useAnimation();
  const history = useHistory();

  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [isLoading, setIsLoading] = useState(false);

  const { userMain, updateUser } = React.useContext(UserContext);

  const { domicilioMain, updateDomicilio } = React.useContext(DomicilioContext);

  const { credito, updateCredito } = React.useContext(TramiteCreditoContext);

  const [shown, setShown] = React.useState(false);
  const [creditoE, setCreditoE] = useState(null);
  const [user, setUser] = useState({
    user: "",
    phone: "",
    password: "",
  });

  const apiClient = ApiClient.getInstance();

  const [verificarSolicitud, setVerificarSolicitud] = React.useState({
    userId: "",
    userLoanId: "",
    isActive: false,
  });
  
  useEffect(() => {
    console.log("called get local storage",localStorage.getItem("creditoLocal") )
    if (localStorage.getItem("userLocal")) {
      var credito = JSON.parse(localStorage.getItem("userLocal"));
      setCreditoE(credito);
    }
  }, []);
  const handleInputChangeUser = (e: { target: { name: any; value: any } }) => {
    console.log("handleInputChange");
    console.log("e");
    console.log(e);
    console.log("e.target.value");
    console.log(e.target.value);

    //var regExp = /[a-zA-Z+-/*. ]/g;
    var regExp = /^[0-9]*$/;
                
    if(regExp.test(e.target.value)){
      if ( e.target.value.length <= 10 ) {
        console.log("if");
        setUser({
          ...user,
          ['phone']: e.target.value,
        });
      }
    } else {
      console.log("letter found");
    }

    console.log("user");
    console.log(user);
  };
  const handleInputChangePassword = (e: { target: { name: any; value: any } }) => {
    console.log("if");
    setUser({
      ...user,
      ['password']: e.target.value,
    });
  };

  const userLogin = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setIsLoading(true);

    if (user.phone === "") {
      setOpenMessage(true);
      setMessage(
        "El número de teléfono del usuario es requerido. Favor de verificar"
      );
      setSeverity("error");
      setIsLoading(false);
      return;
    }
    if (user.phone.length != 10) {
      setOpenMessage(true);
      setMessage(
        "Debes de capturar tu número de teléfono de 10 dígitos"
      );
      setSeverity("error");
      setIsLoading(false);
      return;
    }

    if (user.password === "") {
      setOpenMessage(true);
      setMessage("La contraseña del usuario es requerido. Favor de verificar");
      setSeverity("error");
      setIsLoading(false);
      return;
    }

    apiClient
      .login_v2(user.phone, user.password)
      .then((res) => res.json())
      .then(async (data) => {
        localStorage.setItem("cxdc", user.phone)
        if (data.error) {
          localStorage.clear();
          setOpenMessage(true);
          const statusCode = data.error?.status || data.error.statusCode;
          setMessage(
            statusCode === 401
              ? "Telefono o contraseña invalidos"
              : data.error.message
          );
          setSeverity("error");
          setIsLoading(false);
          if (statusCode === 403) {
            localStorage.setItem("cxdc", user.phone);
            localStorage.setItem("fwcd", user.password);

            //localStorage.setItem("TOKEN", data.token);
            localStorage.setItem("USER_ID", data.userId);

              console.log(creditoE, userMain);

            if ( data.is_pabs ) {
              localStorage.setItem("loanType", "Titulo");
              return history.push("/Titulo?from=Login");
            } else if ( data.is_personal ) {
              localStorage.setItem("loanType", "Personal");
              return history.push("/Personal?from=Login");
            }

            if (creditoE !== null) {
              console.log(creditoE, userMain);
              if(creditoE.creditType === "personal" && userMain.creditType === "personal"){
                localStorage.setItem("loanType", "Personal");
                return history.push("/Personal?from=Login");
              } else if(creditoE.creditType === "titulo" && userMain.creditType === "titulo"){
                localStorage.setItem("loanType", "Titulo");
                return history.push("/Titulo?from=Login");
              } else {
                return history.push("/?from=not-has-credit-type")
              }
            } else {/**no se ha elegido el tipop de credito */
              return history.push("/option-login")
            }
            // if (userMain.creditType === "personal") {
            //   return history.push("/Personal?from=Login");
            // } else {
            //   return history.push("/Titulo?from=Login");
            // }
          } else {
            return history.push("/login");
          }
        }

        localStorage.setItem("TOKEN", data.token);
        localStorage.setItem("USER_ID", data.userId);

        apiClient.token = data.token;
        apiClient.userId = data.userId;
        if (data.fullName) {
          updateUser({
            ...userMain,
            nombre: data.fullName || data.fullName,
            telefono: data.phone || data.phone,
            preAmount: 0,
          });
        } else {
          updateUser({
            ...userMain,
            telefono: data.phone || data.phone,
            preAmount: 0,
          });
        }
        if (!(await apiClient.userHasUserDetails())) {
          console.log('!(await apiClient.userHasUserDetails())')
          return history.push("/register-form");
        }

        try {
          const activeCredit = await apiClient.getActiveCredit();
          const inactiveCredit = await apiClient.getInactiveCredit();
          if (activeCredit != null) {
            const credit = await apiClient.getCredit(activeCredit.creditType );
            updateUser({
              ...userMain,
              nombre: data.fullName,
              curp: credit.curp,
              rfc: credit.rfc,
              telefono: data.phone,
            });

            updateCredito({
              ...credito,
              idPrestamo: credit.id,
              fecha_solicitud: credit.creationDate,
              isDelivered: credit.delivered,
              isApproved: credit.status === "ACCEPTED" ? true : false,
              importe: credit.amount,
              estatusSolicitud:
                credit.status === "IN_PROGRESS"
                  ? "revision"
                  : credit.status === "ACCEPTED"
                  ? "aprobado"
                  : credit.status === "DENIED"
                  ? "solicitud-no-aprobada"
                  : credit.status === "REJECTED"
                  ? "solicitud-no-aprobada"
                  : "sin-solicitud",
              creditType: activeCredit.creditType,
            });

            if (!credit && (await apiClient.userHasUserDetails())) {
              return history.push("/register-form");
            }

            if (credit.status === "ACCEPTED" && credit.acceptContract == null) {
              console.log(1);
              return history.push("/dashboard");
            } else if (
              credit.status === "ACCEPTED" &&
              credit.acceptContract != null
            ) {
              console.log(2);
              return history.push("/dashboard");
            } else if (
              credit.status === "IN_PROGRESS" &&
              (credit.questionsPepsId == null || credit.filesLoanId == null) &&
              activeCredit.creditType == "pabs" &&
              PABS_ACTIVE
            ) {
              localStorage.setItem("loanType", "Titulo");
              console.log(3);
              return history.push("/PABS");
            } else if (
              credit.status === "IN_PROGRESS" &&
              activeCredit.creditType == "personal" &&
              PERSONAL_ACTIVE
            ) {
              localStorage.setItem("loanType", "Personal");
              console.log(4);
              return history.push("/prestamo-personal");
            } else if (
              credit.status === "IN_PROGRESS" &&
              activeCredit.creditType == "diremovil" &&
              PERSONAL_ACTIVE
            ) {
              console.log(4);
              return history.push("/prestamo-diremovil");
            } else if (
              credit.status === "IN_PROGRESS" &&
              activeCredit.creditType == "diremovil-promotion" &&
              PERSONAL_ACTIVE
            ) {
              console.log(4);
              return history.push("/prestamo-diremovil-promotion");
            } else if (
              credit.status === "IN_PROGRESS" &&
              activeCredit.creditType == "pabs" &&
              credit.personalReferences !== null &&
              credit.addressId !== null &&
              credit.laborDataId !== null &&
              credit.questionsPepsId !== null &&
              credit.filesLoanId !== null &&
              credit.userDetailsId !== null &&
              credit.addressPayId !== null &&
              PABS_ACTIVE
            ) {
              localStorage.setItem("loanType", "Titulo");
              console.log("new else if");
              return history.push("/PABS");
            } else {
              console.log(5);
              return history.push("/");
            }
          } else if (inactiveCredit != null) {
            const creditI = await apiClient.getCredit(
              inactiveCredit.creditType
            );
            updateCredito({
              ...credito,
              idPrestamo: creditI.id,
              fecha_solicitud: creditI.creationDate,
              isDelivered: creditI.delivered,
              isApproved: creditI.status === "ACCEPTED" ? true : false,
              importe: creditI.amount,
              estatusSolicitud:
                creditI.status === "IN_PROGRESS"
                  ? "revision"
                  : creditI.status === "ACCEPTED"
                  ? "aprobado"
                  : creditI.status === "DENIED"
                  ? "solicitud-no-aprobada"
                  : creditI.status === "REJECTED"
                  ? "solicitud-no-aprobada"
                  : "sin-solicitud",
              creditType: inactiveCredit.creditType,
            });

            if (
              inactiveCredit.creditType === "personal" &&
              creditI.status === "DENIED"
            ) {
              return history.push("/RECHAZADO/personal/DENIED");
            } else if (
              inactiveCredit.creditType === "pabs" &&
              creditI.status === "DENIED"
            ) {
              return history.push("/RECHAZADO/pabs/DENIED");
            } else if (
              inactiveCredit.creditType === "personal" &&
              creditI.status === "REJECTED"
            ) {
              return history.push("/RECHAZADO/personal/DENIED");
            } else if (
              inactiveCredit.creditType === "pabs" &&
              creditI.status === "REJECTED"
            ) {
              return history.push("/RECHAZADO/pabs/DENIED");
            } else if (
              inactiveCredit.creditType === "diremovil" &&
              creditI.status === "DENIED"
            ) {
              return history.push("/RECHAZADO/personal/DENIED");
            }
          } else if (
            data.is_pabs === true &&
            PABS_ACTIVE
          ) {
            localStorage.setItem("loanType", "Titulo");
            console.log(8);
            return history.push("/PABS");
          } else if (
            data.is_pabs === false &&
            PERSONAL_ACTIVE
          ) {
            localStorage.setItem("loanType", "Personal");
            console.log(8);
            return history.push("/prestamo-personal");
          } else {
            console.log(6);
            return history.push("/");
          }
        } catch (e) {
          if (e.message === "The given user doesn´t have any credit") {
            console.log(7);
            return history.push("/");
          }
          console.log(8, e.message);
          return history.push("/");
        }
      });
  };

  const handleClose = () => {
    setOpenMessage(false);
  };

  useEffect(() => {
    if (inView) {
      console.log(1);
      animation.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 0.8 },
      });
    }
    if (!inView) {
      animation.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
    }
  }, [inView]);

  const switchShown = () => setShown(!shown);
  return (
    <motion.div
      className="blue-background-mess"
      // initial={{ y:'50%', opacity:0, scale: 0.5 }}
      // animate={{y:0, opacity:1, scale: 1 }}
      // exit={{y: "50%", transition:{duration:0.6}}}
      // transition={{ ease: "easeInOut", duration: 0.6 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ ease: "easeInOut", duration: 0.6 }}
    >
      <Navbar />
      <AlertMessage
        open={openMessage}
        message={message}
        handleClose={handleClose}
        severity={severity}
      />
      <div>
        <div className="row">
          <div className="col-2"></div>

          <motion.div animate={animation} className="col-8" ref={ref}>
            <form className="col-12" onSubmit={userLogin}>
              <div className="d-flex justify-content-center mb-1">
                <img
                  src={logo_balnco}
                  alt="PRADO'S"
                  className="img-fluid p-2 img-client mt-5 mb-4"
                  width="240"
                />
              </div>
              <div className="text-white text-center">
                <h4 className="mt-2">Ingresa usuario y contraseña</h4>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-3"></div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label htmlFor="userPhone" className="form-label white-tittle">
                      Número de teléfono
                    </label>
                    <input
                      className="form-control"
                      name="userPhone"
                      placeholder=""
                      aria-label="Default select example"
                      onChange={handleInputChangeUser}
                      value={user.phone}
                      /*onChange={(e) => {
                        console.log("onchange");
                        const value = e.target.value || "";
                        if (value.trim().length <= 10) {
                          console.log("if");
                          this.setState({
                            [e.target.name]: e.target.value
                          });
                        }
                      }}*/
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="userPassword"
                      className="form-label white-tittle"
                    >
                      Contraseña
                    </label>
                    <input
                      className="form-control"
                      name="userPassword"
                      type={shown ? "text" : "password"}
                      placeholder=""
                      aria-label="Default select example"
                      onChange={handleInputChangePassword}
                    />
                    <a
                      className="a_none_style text-black"
                      href="/reset-password"
                    >
                      ¿Olvidaste tu contraseña?
                    </a>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-3"></div>
              </div>
              <div className="d-flex justify-content-center mt-2 mb-5">
                <button
                  className="btn btn-continue-large mt-5"
                  onClick={userLogin}
                >
                  Iniciar sesión
                </button>
              </div>
            </form>
          </motion.div>

          <div className="col-2"></div>
        </div>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.appBar + 1 }}
        open={isLoading}
      >
        <div className="text-center">
          <p className="text-strong">
            Estamos validando tus datos <br></br><span className="text-light-blue">¡No te vayas!</span>
          </p>
          <img
            src={logo_balnco}
            alt="PRADO'S"
            className="img-fluid p-3 img-client hidden"
            width="300"
          />
          <CircularProgress color="primary" size={80} />
        </div>
       
      </Backdrop>
      <Footer />
    </motion.div>
  );
}
export default Login;

