import React, { useEffect, useState } from "react";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { TransitionProps } from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Backdrop from "@mui/material/Backdrop";
import LoanTypeChange from "./loan-type-change.component";
import CircularProgress from "@mui/material/CircularProgress";
import logo_balnco from "../img/logo_blanco.png";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ApiClient from "../services/apiclient";
import { useLocation } from "react-router-dom";

const LoanType = (params: any) => {

  const apiClient = ApiClient.getInstance();

  const [loanTypeChange, setLoanTypeChange] = useState("-");
  const [showDialog, setShowDialog] = React.useState(false);
  const [userActivated, setUserActivated] = React.useState(true);
  function useQuery2() {
    const { search: search2 } = useLocation();
    return React.useMemo(() => new URLSearchParams(search2), [search2]);
  }
  const query2 = useQuery2();

  const checkFromRoute2 = async () => {
    const from = query2.get("from");
    if (from === "Login") {
      setUserActivated(false);
    } else {
      setUserActivated(true);
    }
  };

  const TransitionChangeLoanType = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  const themeChangeLoanType = useTheme();
  const fullScreen = useMediaQuery(themeChangeLoanType.breakpoints.down("md"));
  const [maxWidth, setMaxWidth] = React.useState<DialogProps["maxWidth"]>("xl");

  const handleCloseDialog = () => {
    console.log("handleCloseDialog click");
    setShowDialog(false);
  };
  const handleShowDialog = () => {
    console.log("handleShowDialog click");
    setShowDialog(true);
  };

  useEffect(() => {
    if (params.loanType === "Personal") {
      setLoanTypeChange(" tengo título PABS");
    } else {
      setLoanTypeChange(" quiero un crédito Personal");
    }
    
    setUserActivated(params.userValidated);

    //checkFromRoute2();
  });

  const handleChandeLoanTypePersonal = async () => {
    handleChandeLoanType("Titulo", "Personal");
  };
  const handleChandeLoanTypeTitulo = async () => {
    handleChandeLoanType("Personal", "Titulo");
  };

  const handleChandeLoanType = async (currentLoanType: any, newLoanType: any) => {
    console.log("handleChandeLoanType apiclient");

    const bodyParams = {
      currentLoanType: currentLoanType,
      newLoanType: newLoanType
    }
    console.log("bodyParams = ", bodyParams);

    
    const USER_ID = localStorage.getItem("USER_ID");

    await apiClient.changeLoanType(USER_ID, bodyParams).then(async (data) => {
      console.log("changeLoanType response = ", data);
      if ( data.is_pabs ) {
        localStorage.setItem("loanType", "Titulo");
        if ( userActivated ) {
          return  window.location.replace("/PABS");
        } else {
          return  window.location.replace("/Titulo?from=Login");
        }
      } else if ( data.is_personal ) {
        localStorage.setItem("loanType", "Personal");
        if ( userActivated ) {
          return  window.location.replace("/prestamo-personal");
        } else {
          return  window.location.replace("/Personal?from=Login");
        }
      }
    }).catch((e) => {
      console.log(e)
    });
  };

  return (
    <>
    <div className="p-0 m-0 mb-1" onClick={handleShowDialog}>
      <div className="card loan-type">
        <div className="container">
          <h6 className="txt-azul-marino rExtraBold mt-2 text-end show-cursor">Tipo de crédito:
            <b className="txt-azul"> {params.loanType}</b>
            <br />
            <span className="text-nav loan-type-font">Modificar:
              <b className="txt-azul-marino">{loanTypeChange}</b>
            </span>
          </h6>
        </div>
      </div>
    </div>
    <Dialog
      open={showDialog}
      onClose={handleCloseDialog}
    >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.appBar + 1 }}
          open={false}
        >
          <img
            src={logo_balnco}
            alt="PRADO'S"
            className="img-fluid p-3 img-client"
            width="300" />
          <CircularProgress color="primary" size={80} />
        </Backdrop>
        <DialogTitle
          id="alert-dialog-slide-change-loan-type"
          sx={{
            backgroundColor: "#005593",
            color: "white",
            fontWeight: 700,
            textAlign: "center",
          }}
        >
          {" "}
          <Grid container spacing={2}>
            <Grid item xs={10} md={10}>
              {"Cambiar tipo de crédito"}
            </Grid>
            <Grid item xs={2} md={2}>
              <IconButton
                aria-label="delete"
                color="inherit"
                onClick={handleCloseDialog}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent>
          <LoanTypeChange 
            loanType = {params.loanType}
            handleChandeLoanTypePersonal = {() => handleChandeLoanTypePersonal()}
            handleChandeLoanTypeTitulo = {() => handleChandeLoanTypeTitulo()}
          />
        </DialogContent>
    </Dialog></>
  );
};

export default LoanType;
